import React from 'react';
import { useIntl } from 'react-intl';

import ExternalLink from '@starbucks-web/pattern-library/lib/components/external-link';
import StarbucksRewardsTrademark from 'shared/app/components/starbucks-rewards-trademark';

import RewardsFooter from '../../shared/rewards-footer';
import getFooterContent from './footer-content';
import UnlinkAccounts from './unlink-accounts';

import messages from './messages';

const MarriottFooter = ({ terms1ReturnHash }) => {
  const { formatMessage } = useIntl();
  const footerContent = getFooterContent({ terms1ReturnHash });

  return (
    <RewardsFooter
      bottomBody={formatMessage(messages.bottomBody, {
        unlinkAccounts: <UnlinkAccounts />,
        starbucksRewardsTrademark: <StarbucksRewardsTrademark />,
      })}
      bottomHeading={formatMessage(messages.bottomHeader)}
      bottomPadding="pb4 lg-pb8"
      mainBody={formatMessage(messages.topContent, {
        cta: (content) => (
          <ExternalLink href="https://customerservice.starbucks.com/sbux?id=topics&article=KB0013260">
            {content}
          </ExternalLink>
        ),
      })}
      mainBodyPadding="pb3"
      questions={footerContent}
      sidePadding="px0 lg-px8"
      topHeading={formatMessage(messages.partnershipFAQs)}
      topPadding="pt4 lg-pt8"
    />
  );
};

export default MarriottFooter;
