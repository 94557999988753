import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import { rewardsMessages } from 'shared/app/messages/rewards';

const RewardsFooter = ({
  mainBody,
  terms,
  questions,
  topHeading,
  bottomHeading,
  bottomBody,
  sidePadding,
  topPadding,
  bottomPadding,
  mainBodyPadding,
  hiddenTitle,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="bg-neutralCool">
      <ContentColumn className="sb-global-gutters--maxWidthLayout" size="xl">
        {hiddenTitle ? (
          <h2 className="hiddenVisually" id="disclaimerTitleId">
            {formatMessage(rewardsMessages.termsAndConditions)}
          </h2>
        ) : null}
        <div className={`py5 md-py7 grid ${sidePadding}`}>
          <div className="text-xxs pb3">
            {topHeading ? (
              <Heading
                className={`text-semibold ${topPadding}`}
                size="sm"
                tagName="h2"
              >
                {topHeading}
              </Heading>
            ) : null}
            <p className={mainBodyPadding}>{mainBody}</p>
            {terms}
          </div>
          {questions.map(
            (
              {
                header,
                descriptions,
                headingClassName,
                headingTagName,
                headingSize,
                headerId,
              },
              idx
            ) => (
              <div
                className={classNames('size12of12 md-size6of12 gridItem', {
                  pb5: idx !== questions.length - 1,
                  'md-pb0': idx > 1,
                  'md-pl5': idx % 2 !== 0,
                })}
                key={`questions-list-item-${idx}`}
              >
                <Heading
                  className={headingClassName}
                  id={headerId}
                  size={headingSize}
                  tagName={headingTagName}
                >
                  {header}
                </Heading>
                {descriptions?.length
                  ? descriptions.map((description, qIdx) => (
                      <p
                        className={classNames('text-xxs', {
                          pb3: qIdx !== descriptions.length - 1,
                        })}
                        key={`questions-${idx}-description-${qIdx}`}
                      >
                        {description}
                      </p>
                    ))
                  : null}
              </div>
            )
          )}
          {bottomHeading ? (
            <div className={`text-xxs pt5 ${bottomPadding}`}>
              <Heading className="text-semibold pb3" size="sm" tagName="h2">
                {bottomHeading}
              </Heading>
              <p>{bottomBody}</p>
            </div>
          ) : null}
        </div>
      </ContentColumn>
    </div>
  );
};

export default RewardsFooter;
