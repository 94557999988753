import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'rewards.rewardTiers.header',
    description: 'Heading for reward tiers section',
    defaultMessage: 'Get your favorites for free',
  },
  tabListAriaLabel: {
    id: 'rewards.rewardTiers.tabListAriaLabel',
    description: 'Accessible label for group of tabs describing Rewards levels',
    defaultMessage: 'Reward levels',
  },
});

export const tabContent = defineMessages({
  tab1Header: {
    id: 'rewards.rewardTiers.tab1.header',
    description: 'Heading for tab 1 of the reward tiers section',
    defaultMessage: 'Customize your drink',
  },
  tab1Content: {
    id: 'rewards.rewardTiers.tab1.content',
    description: 'Content for tab 1 of the reward tiers section',
    defaultMessage:
      'Make your drink just right with an extra espresso shot, nondairy milk or a dash of your favorite syrup.',
  },
  tab2Header: {
    id: 'rewards.rewardTiers.tab2.header',
    description: 'Heading for tab 2 of the reward tiers section',
    defaultMessage:
      'Brewed hot or iced coffee or tea, bakery item, packaged snack and more',
  },
  tab2Content: {
    id: 'rewards.rewardTiers.tab2.content',
    description: 'Content for tab 2 of the reward tiers section',
    defaultMessage:
      'Treat yourself to an iced coffee, buttery croissant, bag of chips and more.',
  },
  tab3Header: {
    id: 'rewards.rewardTiers.tab3.header',
    description: 'Heading for tab 3 of the reward tiers section',
    defaultMessage:
      'Handcrafted drink (Cold Brew, lattes and more) or hot breakfast',
  },
  tab3Content: {
    id: 'rewards.rewardTiers.tab3.content',
    description: 'Content for tab 3 of the reward tiers section',
    defaultMessage:
      'Turn good mornings great with a delicious handcrafted drink of your choice, breakfast sandwich or oatmeal on us.',
  },
  tab4Header: {
    id: 'rewards.rewardTiers.tab4.header',
    description: 'Heading for tab 4 of the reward tiers section',
    defaultMessage: 'Sandwich, protein box or at-home coffee',
  },
  tab4Content: {
    id: 'rewards.rewardTiers.tab4.content',
    description: 'Content for tab 4 of the reward tiers section',
    defaultMessage:
      'Enjoy a PM pick-me-up with a lunch sandwich, protein box or a bag of coffee—including Starbucks VIA Instant®.',
  },
  tab5Header: {
    id: 'rewards.rewardTiers.tab5.header',
    description: 'Heading for tab 5 of the reward tiers section',
    defaultMessage: 'Select Starbucks® merchandise',
  },
  tab5Content: {
    id: 'rewards.rewardTiers.tab5.content',
    description: 'Content for tab 5 of the reward tiers section',
    defaultMessage:
      'Take home a signature cup, drink tumbler or your choice of coffee merch up to $20.',
  },
});

export default messages;
